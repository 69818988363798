import { invoicesHeader } from "./invoicesHeader";
import { showFinanceTile } from "./showFinanceTile";
import { showEmploymentHistoryTile } from "./showEmploymentHistoryTile";
import { applicationSubmittedText } from "./applicationSubmittedText";
import { nationalInsuranceText } from "./nationalInsuranceText";
import { showNationalInsuranceNumber } from "./showNationalinsuranceNumber";
import { requireVisaText } from "./requireVisaText";
import { currencySymbol } from "./currencySymbol";
import { currency } from "./currency";
import { taxAbbreviation } from "./taxAbbreviation";
import { australiaBankDetails } from "./australiaBankDetails";
import { superannuationDetails } from "./superannuationDetails";
import { associateFooter } from "./associateFooter";
import { termsAndConditionsMessage } from "./termsAndConditionsMessage";
import { taxDetailSettings } from "./taxDetails";
import { taxNumberValidation } from "./taxNumberValidation";
import { phoneNumberValidation } from "./phoneNumberValidation";
import { showAssociateInvoicing } from "./showAssociateInvoicing";
import { showClientInvoicing } from "./showClientInvoicing";
import { showClientInvoicingMenuItem } from "./showClientInvoicingMenuItem";
import { showDashboardAssociateSection } from "./showDashboardAssociateSection";
import { projectEmploymentSector } from "./projectEmploymentSector";
import { requireVisaDetailText } from "./requireVisaDetailText";
import { clientLandingPhoneNumber } from "./clientLandingPhoneNumber";
import { clientLandingEmailAddress } from "./clientLandingEmailAddress";
import { referencingHeaderMessage } from "./referencingHeaderMessage";
import { showAustraliaFinance } from "./showAustraliaFinance";
import { associateLandingFooter } from "./associateLandingFooter";
import { vettingItemSettings } from "./vettingItems";
import { backgroundScreeningDocuments } from "./backgroundScreeningDocuments";
import { temporaryAddressEnabled } from "./temporaryAddressEnabled";
import { dateOfBirthEnabled } from "./dateOfBirthEnabled";
import { bloodGroupEnabled } from "./bloodGroupEnabled";
import { stateAddressFieldEnabled, stateAddressFieldNotEnabled } from "./stateAddressFieldEnabled";
import { address } from "./address";
import { values as bankSettings } from "./bankDetails/index";
import { emergencyContactAddress } from "./emergencyContactAddress";
import { associateSearchSettings } from "./associateSearch";
import { confirmationOfAssignmentEmail } from "./confirmationOfAssignmentEmail";
import { showPerformanceClause } from "./showPerformanceClause";
import { showAssociateApplicationsAndContractsCard } from "./showAssociateContractsCard";
import { showDisbursementArrangement } from "./showDisbursementArrangement";
import { showHealthRisk } from "./showHealthRisk";
import { showServicesDelivered } from "./showServicesDelivered";
import { momentaTeamsInfo } from "./momentaTeamsInfo";
import { employmentTypeRequired } from "./employmentTypeRequired";
import { requireUmbrellaCompanyContact } from "./requireUmbrellaCompanyContact";
import { showFinanceInformationCard } from "./showFinanceInformationCard";
import { showDayRateChargeRateOnContract } from "./showDayRateChargeRateOnContract";
import { internalSourceDropDownOptions } from "./internalSourceDropDownOptions";
import { showUmbrellaCompanyDetails } from "./showUmbrellaCompanyDetails";
import { showWorkingDay } from "./showWorkingDay";
import { showFieldGlassImportHistory } from "./showFieldGlassImportHistory";
import { showAcademicBullet } from "./showAcademicBullet";
import { showForecasting } from "./showForecasting";

export const values = {
    invoicesHeader,
    showFinanceTile,
    showEmploymentHistoryTile,
    applicationSubmittedText,
    nationalInsuranceText,
    requireVisaText,
    requireVisaDetailText,
    currencySymbol,
    currency,
    taxAbbreviation,
    australiaBankDetails,
    superannuationDetails,
    ...taxDetailSettings,
    taxNumberValidation,
    phoneNumberValidation,
    associateFooter,
    termsAndConditionsMessage,
    showAssociateInvoicing,
    showClientInvoicing,
    showForecasting,
    showClientInvoicingMenuItem,
    showDashboardAssociateSection,
    projectEmploymentSector,
    clientLandingPhoneNumber,
    clientLandingEmailAddress,
    referencingHeaderMessage,
    showAustraliaFinance,
    associateLandingFooter,
    ...vettingItemSettings,
    ...associateSearchSettings,
    backgroundScreeningDocuments,
    temporaryAddressEnabled,
    dateOfBirthEnabled,
    bloodGroupEnabled,
    stateAddressFieldEnabled,
    stateAddressFieldNotEnabled,
    address,
    ...bankSettings,
    emergencyContactAddress,
    confirmationOfAssignmentEmail,
    showPerformanceClause,
    showAssociateApplicationsAndContractsCard,
    showDisbursementArrangement,
    showHealthRisk,
    showServicesDelivered,
    showDayRateChargeRateOnContract,
    internalSourceDropDownOptions,
    ...momentaTeamsInfo,
    employmentTypeRequired,
    showWorkingDay,
    showUmbrellaCompanyDetails,
    showFinanceInformationCard,
    requireUmbrellaCompanyContact,
    showFieldGlassImportHistory,
    showNationalInsuranceNumber,
    showAcademicBullet
};

export type LocalTextValues = typeof values;
export type LocalTextKey = (keyof typeof values);
