import * as React from "react";
import { connect } from "react-redux";
import { Container, Menu } from "semantic-ui-react";
import { Fragment, Link, State as RouterState, Location } from "redux-little-router";
import { ToastContainer } from "react-toastify";
import { ToastMessage } from "@momenta/common/toasts";
import { CdnImage, CdnImg, EnvironmentLabel, LoadingWrapper } from "@momenta/common";
import { ErrorBoundaryRouted } from "@momenta/common/ErrorBoundaryRouted";
import { isRequestActive } from "redux-request-loading";
import { LocalText } from "@momenta/common/internationalisation/LocalText";
import { Country } from "@momenta/common/model";

import EditFinance from "./currentUser/EditFinance";
import { AppState } from "./model";
import { Timesheets } from "./timesheets/Timesheets";
import { Dashboard } from "./PortalDashboard";
import { CurrentUser } from "./currentUser/model";
import { Register } from "./register/Register";
import { LoadingBar } from "./LoadingBar";
import { EditProfile } from "./currentUser/EditProfile";
import { OfflineStatus } from "./connectionStatus/OfflineStatus";
import { EditDocuments } from "./currentUser/documents/EditDocuments";
import { InvoiceDetails } from "./invoicePeriods/InvoiceDetails";
import { PreferencesAndConsentView } from "./currentUser/preferences/PreferencesAndConsentView";
import { Referencing } from "./referencing/Referencing";
import { Landing } from "./Landing";
import { ViewContracts } from "./contracts/ViewContracts";
import { InvoicePeriodPrint } from "./invoicePeriods/invoicePeriodPrint";
import { MomentaTeams } from "./momentaTeams/MomentaTeams";
import { JobLink } from "./jobLink/JobLink";
import { ApplicationSubmitted } from "./applicationSubmitted/ApplicationSubmitted";
import { ViewFinanceInformation } from "./financeInformation/ViewFinanceInformation";
import { RightToWorkUsa } from "./rightToWork/RightToWorkUsa";

interface AppStateProps {
    currentUser: CurrentUser;
    online: boolean;
    loading: boolean;
    location: Location;
    isUsaRegion: boolean;
}

const AppUnconnected: React.FC<AppStateProps> = ({ currentUser, online, loading, location, isUsaRegion }) => {
    const authMenu = currentUser.id === undefined
        ? (
            <Menu.Menu position="right" className="account">
                <OfflineStatus online={online} />
                <Menu.Item as="a" href="/account/signin">Sign-In</Menu.Item>
                { isUsaRegion ?
                    <Menu.Item as={Link} href="/right-to-work">Sign-Up</Menu.Item> :
                    <Menu.Item as="a" href="/account/signup">Sign-Up</Menu.Item>}
            </Menu.Menu>
        )
        : (
            <Menu.Menu position="right" className="account">
                <OfflineStatus online={online} />
                <Menu.Item as="a" href="/account/signout">Sign out</Menu.Item>
            </Menu.Menu>
        );

    const isAuthenticated = () => { return currentUser.authenticated; };
    const isNotAuthenticated = () => { return currentUser.authenticated === false; };
    const showFooter = () => { return location.pathname.includes("/timesheets/") === false; };
    const isRegisterPage = () => { return location.pathname === "/register"; };
    const isNotRegisterPage = () => { return location.pathname !== "/register"; };
    const isJobLinkPage = () => { return location.pathname.startsWith("/jobLink"); };
    const isRightToWorkPage = () => { return location.pathname.startsWith("/right-to-work"); };
    const showAuthMenu = () => isAuthenticated() || (isJobLinkPage() === false && isRightToWorkPage() === false);

    return (
        <>
            <div className={currentUser.authenticated ? "authenticated" : "unauthenticated"}>
                <LoadingBar />
                <ToastContainer hideProgressBar />

                <Fragment withConditions={isNotRegisterPage}>
                    <Menu as="header" className="nomargintop top-menu" borderless>
                        <Container>
                            <Menu.Item as={Link} href="/" className="logo"><CdnImg src="/momenta-logo-white-text.png" /></Menu.Item>
                            <Menu.Item><EnvironmentLabel /></Menu.Item>
                            {showAuthMenu() && authMenu}
                        </Container>
                    </Menu>
                </Fragment>

                <Fragment withConditions={isRegisterPage}>
                    <Container>
                        <CdnImage src="/momenta-logo.png" size={"small"} />
                    </Container>
                </Fragment>

                <LoadingWrapper loading={loading}>
                    <Fragment forRoute="/jobLink/:jobId">
                        <JobLink />
                    </Fragment>
                    <Fragment forRoute="/" withConditions={isNotAuthenticated}>
                        <>
                            <Fragment forRoute="/">
                                <Landing />
                            </Fragment>
                        </>
                    </Fragment>
                    <Fragment forRoute="/right-to-work">
                        <RightToWorkUsa />
                    </Fragment>

                    <Fragment forRoute="/" withConditions={isAuthenticated}>
                        <Container className="main-content">
                            <ErrorBoundaryRouted>
                                <Fragment forRoute="/">
                                    <Dashboard />
                                </Fragment>
                                <Fragment forRoute="/profile">
                                    <EditProfile />
                                </Fragment>
                                <Fragment forRoute="/referencing">
                                    <Referencing />
                                </Fragment>
                                <Fragment forRoute="/timesheets">
                                    <Timesheets />
                                </Fragment>
                                <Fragment forRoute="/invoicePeriod/:invoicePeriodId">
                                    <>
                                        <Fragment forRoute="/print">
                                            <InvoicePeriodPrint />
                                        </Fragment>
                                        <Fragment forRoute="/">
                                            <InvoiceDetails />
                                        </Fragment>
                                    </>
                                </Fragment>
                                <Fragment forRoute="/register">
                                    <Register />
                                </Fragment>

                                <Fragment forRoute="/application-submitted">
                                    <ApplicationSubmitted />
                                </Fragment>

                                <Fragment forRoute="/finance-information">
                                    <ViewFinanceInformation />
                                </Fragment>
                                <Fragment forRoute="/finance">
                                    <EditFinance />
                                </Fragment>
                                <Fragment forRoute="/documents">
                                    <EditDocuments />
                                </Fragment>
                                <Fragment forRoute="/preferences">
                                    <PreferencesAndConsentView />
                                </Fragment>
                                <Fragment forRoute="/contracts">
                                    <ViewContracts />
                                </Fragment>
                                <Fragment forRoute="/momenta-teams">
                                    <MomentaTeams />
                                </Fragment>
                            </ErrorBoundaryRouted>
                        </Container>
                    </Fragment>

                </LoadingWrapper>

                {isAuthenticated() && showFooter() && <LocalText keyName="associateFooter" />}
                {isNotAuthenticated() && <LocalText keyName="associateLandingFooter" />}
                <ToastMessage />
            </div>
        </>
    );
};

const mapStateToProps = (state: AppState & RouterState): AppStateProps => ({
    currentUser: state.currentUser,
    online: state.connection.online,
    loading: isRequestActive(state, "loadCurrentUser"),
    location: state.router,
    isUsaRegion: state.region === Country.Usa
});

export const App = connect(mapStateToProps)(AppUnconnected);
